import React, { createContext, useState } from 'react';

export const FileContext = createContext();

export const FileProvider = ({ children }) => {
    const [file, setFile] = useState([]);
    const [filePreview, setFilePreview] = useState(null);
    const [fileError, setFileError] = useState(null);
    const [showUploadPrompt, setShowUploadPrompt] = useState(false);

    
    const handleFileChange = (event) => {
        const supportedTypes = ['application/pdf','text/plain'];
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            if (supportedTypes.includes(selectedFile.type)) {
                console.log("Selected file: ", selectedFile);
                console.log("Selected file type: ", selectedFile.type);

                setFile(selectedFile);
                setFilePreview(selectedFile.name);
                setFileError(null);
            } else {
                setFileError("File type not currently supported. Please upload a .pdf or .txt file.");
               
                setFile(selectedFile);
                setFilePreview(selectedFile.name);
            }

            const maxSize = 50 * 1024 * 1024; // 50MB in bytes
            if (selectedFile.size > maxSize) {
                setFileError("File size exceeds the limit. Please upload a smaller file.");
                setFile(selectedFile);
                setFilePreview(selectedFile.name);
            }
        }
    };

    const handleRemoveFile = (event) => {
        event.preventDefault();
        setFile(null);
        setFilePreview(null);
        setFileError(null);
        document.getElementById('file-input').value = '';

    }

    const handleUploadClick = async (e) => {
        e.preventDefault();
        document.getElementById('file-input').click();
    }

    return (
        <FileContext.Provider value={{ 
            file, setFile, 
            filePreview, setFilePreview, 
            fileError, setFileError, 
            showUploadPrompt, setShowUploadPrompt,
            handleUploadClick,
            handleRemoveFile,
            handleFileChange
            }}>
            {children}
        </FileContext.Provider>
    );
};